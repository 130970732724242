import { DragEndEvent, DragStartEvent, KeyboardSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { useCallback, useState } from "react";

interface IUseSortableListProps<T extends { id: UniqueIdentifier }> {
    updateItems?: (newItems: T[]) => void;
    items: T[];
    updateIndex?: (oldIndex: number, newIndex: number) => void;
}

const useSortableList = <T extends { id: UniqueIdentifier }>({ updateItems, updateIndex, items }: IUseSortableListProps<T>) => {
    // current id of item under drag
    const [activeId, setActiveId] = useState<UniqueIdentifier | undefined>();

    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: { distance: 10 },
    });
    const keyboardSensor = useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    });
    const sensors = useSensors(pointerSensor, keyboardSensor);

    const onDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id);
    }, []);

    const onDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (over == null) {
                return;
            }

            if (active.id === over.id) {
                return;
            }

            const oldIndex = items.findIndex(({ id }) => id === active.id);
            const newIndex = items.findIndex(({ id }) => id === over.id);

            if (updateItems !== undefined) {
                const newItems = arrayMove(items, oldIndex, newIndex);
                updateItems(newItems);
            }

            if (updateIndex !== undefined) {
                updateIndex(oldIndex, newIndex);
            }

            setActiveId(undefined);
        },
        [updateItems, items, updateIndex],
    );

    return { activeId, sensors, onDragEnd, onDragStart };
};

export default useSortableList;
